<template lang="pug">
  v-container
    v-row
      v-col
        .details.mb-10
          v-layout(
            align-center
          )
            h1.text-h4 {{ product.name }}
            v-col.d-flex
              .actions.ml-auto
                v-chip(
                  small
                ) {{ product.type }}
                v-tooltip(top)
                  template(#activator="{ on, attrs}")
                    v-btn.px-0.mr-2.my-1(
                      v-on="on"
                      min-width="40px"
                      @click="deleteProduct"
                      color="error"
                    )
                      v-icon mdi-delete
                  span Delete Product
                v-btn.ml-auto(
                  :to="{ name: 'edit-product', params: { productId: product.id }}"
                  color="primary"
                )
                  v-icon(
                    left
                  ) mdi-pencil
                  | Edit Product

</template>

<script>
import { useGet } from 'feathers-vuex'

export default {
  name: 'Product',
  props: {
    productId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const { Product } = context.root.$FeathersVuex.api

    // Get the patient record
    const { item: product, hasLoaded } = useGet({
      model: Product,
      id: props.productId
    })

    return {
      product,
      hasLoaded
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async deleteProduct () {
      try {
        const message = 'Are you sure you want to delete this product?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        const currentProduct = this.product

        if (isYes) {
          const product = this.product
          await this.product.remove()
          this.$snackSuccess('Product Deleted')

          if (currentProduct.type === 'GLOBAL' && this.user.isAdmin) {
            this.$router.push({ name: 'global-products' })
          } else {
            this.$router.push({ name: 'shop-products', params: { shopId: product.shop_id } })
          }
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  data: () => ({

  })
}
</script>
